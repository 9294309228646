//
// Pathbar stylesheet based on Plone 5 Styles
// -----------------------------------------------


.app-pathbar {
  @extend .container;
  margin-bottom: $spacer;
  padding: 0 .5rem;
  font-size: $font-size-sm;
  border-radius: 0;
  background-color: #eee;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.05);
  .breadcrumb {
  	background-color: transparent;
  	margin-bottom: 0;
  }
  > * > span { //you are here
    display: inline-block;
    color: $gray-300;
    padding: 1rem 0 1rem 1rem;
    font-weight: 100;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  ol {
    display: inline-block;
    padding: 1rem 0;
    list-style: none;
    margin: 0;
    > li {
     font-weight: 300;
     display: inline-block;
     line-height: 0; // match ol & span height
     position: relative;
     padding: 0;
     + li:before {
       content: " / ";
       padding: 0 0.25rem;
       color: #000;
     }
   }
    > #breadcrumbs-current {
      color: $breadcrumb-active-color;
    }
  }
  body.template-frontpage-view & {display: none;} //not breadcrumbs on homepage
}
