.app-searchbox {
    width: 100%;
}
.app-searchbox-group {
    display: flex;
    position: relative;
    width: 100%;
}

.app-searchbox-item {
    @include make-col(9);
    &:first-child {
        @include make-col(3);
    }
}

.app-searchbox-field {
    @extend .form-control;
    border-color: $gray-300;
    &::placeholder {
        color: $gray-300;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }
}

.app-searchbox-control {
    flex: 1;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 0;
    font-size: 0.75rem;
    @include hover-focus-active {
        z-index: 3;
    }
    @extend .form-control;
    border-color: $gray-300;
    &::placeholder {
        color: $gray-600;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }
}

.app-searchbox-action {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    vertical-align: middle;
}

.app-searchbox-btn {
    position: relative;
    font-size: 1rem;
    @extend .btn;
    @include button-variant($gray-300, $gray-300, $gray-300);
    // Bring the "active" button to the front
    @include hover-focus-active {
        z-index: 3;
    }
}
