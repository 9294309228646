//
// Callouts
// --------------------------------------------------


.app-callout {
  padding: 0.5rem 1rem 1rem;
  margin: $spacer 0;
  border-left: 5px solid #eee;
}

// Common spacing and style resets
.app-callout-title {
  margin-top: 0;
  margin-bottom: .25rem;
}

.app-callout p:last-child {
  margin-bottom: 0;
}

// Custom button styles
.app-callout-btn {
  float: right;
  margin-bottom: .5rem;
}

// Callout variants used as addon classes
.app-callout-default {
    @include alert-variant($gray-300, $gray-500, $black);
}

.app-callout-success {
    @include alert-variant($gray-200, $green, $green);
}

.app-callout-danger {
    @include alert-variant($gray-200, $red, $red);
}

.app-callout-warning {
    @include alert-variant($gray-200, $yellow, $yellow);
}