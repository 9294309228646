//
// Basic scaffolding and resets
// ----------------------------------

.app-fonts-loaded,
.fonts--loaded {
    font-family: $app-font-family-base;
    font-size: 14px;
}

//** Adjust font weight for initialized webfonts
body {
    background-color: $app-gray-light;
    font-family: $font-family-base;
    .wf-active &,
    .app-fonts-loaded & {
        font-family: $app-font-family-base !important;
        font-weight: 300;
    }
    @include when-inside('.fonts--loaded') {
        font-family: $app-font-family-base;
        font-weight: 300;
    }
    &.template-theming-controlpanel .row {
        // Fix theming control panel
        margin: 0 $spacer;
    }
}

// Set font weights if webfont is active
.wf-active,
.app-fonts-loaded {
    b,
    strong {
        font-weight: 600;
    }
}

// prevent fake bolds
b,
strong {
    font-weight: 700;
    .wf-active &,
    .app-fonts-loaded & {
        font-weight: 600;
    }
}

em {
    font-weight: 400;
}

// Remove dotted outline
a {
    outline: 0;
}

// Transistion link color on :hover state
a:hover {
    transition: color 0.5s linear;
}


// Optional plone settings and adjustments
// ---------------------------------------
// Combat plone.app.widget styling (also using bootstrap) and correct
// display errors caused by grid system inconsistencies

// Combat plone hidden element class via mixin
.hidden,
.hiddenStructure {
    visibility: hidden;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/
.hide,
.hiddenStructure {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    color: #fff;
    background-color: #000;
}
.hiddenStructure a {
    color: #fff;
    background-color: #000;
}

.pat-structure .navbar {
    display: block;
    > .btn-group {
        margin-top: 1rem;
    }
}

// Reenable type selection in navigation control panel
// Hidden by bootstrap label component
.template-navigation-controlpanel .label:empty {
    display: inline;
}

// Reset widget styling
.mce-edit-area,
#content .field textarea,
#content .field input[type="text"] {
    background: #fff;
    background-image: none;
}

// Fix recurrence widget overlay styling
div.riform .label {
    float: none;
}
// Fix datepicker header year + month select
.pattern-pickadate-wrapper .picker__select--month,
.pattern-pickadate-wrapper .picker__select--year {
    display: inline-block;
}

// Force search button to display styled
.searchButton {
    @extend .btn;
}

// Reset tiny mce content body styling for better readability
.mceContentBody {
    padding: $spacer;
}

// Toolbar icon for new contributing view
.icon-contributing {
    &:before {
        content: '\e820';
    }
}

// Quick fix for missing default styles
.link-location {
    display: block;
}

// Combat updated bootstrap upstream classes
.collapse {
    display: none;
    &.in {
        display: block;
    }
}
