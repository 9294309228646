@page {
    size: A4;
}

@media print {
    // Basic body resets
    body {
        background: white;
        display: block;
        overflow: visible !important;
        margin: 0 auto .5cm auto;
        // Use well readable system fonts for printing
        font-family: Trebuchet MS, 'Times New Roman', serif;
        font-size: 1em;
    }

    .dim-in {
        opacity: 1 !important;  /* make things visible upon start */
    }

    svg {
        position: absolute !important;
    }

    // Remove edit bar
    #edit-bar {
        display: none;
    }

    // Hide components
    .app-print--hidden,
    .app-header,
    .app-pathbar,
    .app-main-aside,
    .app-contact-panel,
    .app-contact-actions,
    .app-footer {
        display: none;
    }
    // Lecture listing defaults
    .app-list__filter-bar,
    .app-list__result-index {
        display: none;
    }
    .app-btn {
        display: none;
    }

    .app-page__header-section {
        margin: 0;
        border-bottom: 0;
    }

    .app-page__title {
        margin-bottom: 0;
    }

    .course-card__title {
        font-size: 1rem;
    }

    .text-muted {
        margin-bottom: 0;
    }

    .course__modules {
        padding-top: 0;
        padding-bottom: 0;
    }

    .course-card--muted {
        display: none;
    }
}


