//
// Application stylesheet
// -----------------------------------------------



.ppe-section-sortable {
  .sortable-placeholder {
    @include size(100%, 8rem);
    margin: $spacer 0;
    border: 2px solid $yellow;
    background: $yellow;
  }
  .sortable-dragging {
    border: 1px solid $red;
    background: #f2f2f2;
    opacity: 1;
  }
}

.ppe-col-info {
    padding-top: ceil(($spacer * .75));
    font-size: $font-size-base;
    color: $gray-300;
    text-transform: uppercase;
}

.ppe-action {
  display: block;
  margin-top: ceil(($spacer * .75));
  margin-right: 0;
  margin-left: floor(($spacer));
  line-height: 1;
  &.ppe-action-primary {
    color: darken($gray-300, 10%);
  }
  &.ppe-action-close {
    opacity: .5;
    &:hover {
        opacity: 1;
    }
  }
  &.ppe-action-transition,
  &.ppe-action-remove {
    margin-right: ceil(($spacer * 2 ));
    padding-right: .5rem;
    border-right: 1px solid $gray-300;
  }
}

.ppe-dragindicator {
    cursor: move;
}

.ppe-row-eq-height {
  display:         flex;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ppe-col-visible {
  // padding-top: 15px;
  padding-bottom: 15px;
  background-color: #f9f9f9;
  &.ppe-col-bordered {
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-left: 1px solid $gray-300;
  }
}

.ppe-col-action {
  background-color: $gray-600;
  border-right: 1px solid $body-color;
  .ppe-link {
    text-align: center;
    color: #fff;
  }
}

.ppe-panel {
  // padding-top: $spacer;
  border-top: 2px solid $gray-600;
  .ppe-panel-icon {
    margin-right: ceil(($spacer / 2));
    padding: 0 ceil(($spacer / 2));
    border-right: 1px solid $gray-600;
  }
  .pp-panel-title {
    margin-bottom: 0;
    > a {
      font-weight: 200;
    }
  }
}

.ppe-block-sortable {
  .ppe-block-gridpreview {
      opacity: 0;
  }
  .ppe-panel-preview {
    color: $gray-300;
      padding: 1rem;
  }
  &:hover {
    .ppe-block-title {
        opacity: .25;
    }
    .ppe-block-gridpreview {
        opacity: 1;
    }
  }
}


.ppe-ratio {
    width: 360px;
    margin: $spacer auto 0;
}

.app-contentblock {
  position: relative;
  display: block;
  clear:both;
  min-height: 1.5rem;
  border-top: 1px solid $gray-800;
  color: $gray-800;
}

.contentpanel-editbar {
    position:absolute;
    top:0;
    right:1px;
    display: none;
    width:100%;
    height:100%;
    padding: ceil($spacer * 1.5) ceil($spacer / 2);
    background:rgba(255, 255, 255, 0.5);
    border-left: 2px solid $gray-800;
    text-align:left;
    color: $body-color !important;
    z-index:2;
}
