//
// Forms
//------------------------------------


// Standard application button styles
.app-btn,
.app-button {
    @extend .btn;
    @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
    @include button-variant(#333, $gray-600, #333);
    @include button-outline-variant($gray-600);
    &-sm {
        @include button-size(.5rem, .5rem, $font-size-sm, $input-btn-line-height-sm, $btn-border-radius-sm);
    }
    &--link {
        border-color: transparent;
    }
    &--standalone {
        margin-bottom: 1rem;
    }
}

.app-button--primary {
    @include button-variant($primary, $primary);
}

//** Webfont specific adjustments
label {
  font-weight: 700;
  .wf-active & {
    font-weight: 400;
  }
}


//== Inputs
//
//## Add basic styling to all input types
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  @extend  .form-control;
}

//** Reset textarea height
textarea {
    height: auto;
}

select[multiple] {
    overflow: hidden;
}

.app-form-actions {
  padding-top: $spacer;
}

.help-block {
    display: block;
    font-size: .85rem;
    font-weight: 300;
    color: $app-gray;
}

[id="form-widgets-groups"] .option {
    display: block;
}

//== Custom classes added by Diazo transforms
//## This styles try to combat unclear semantics
//## produced by the Plone form libraries
.form__field--checkbox {
    .option {
        display: inline-block;
        margin-right: 1rem;
        > label {
            margin-left: .5rem;
            font-weight: 300;
        }
    }
    > .option > label {
        font-weight: bold;
    }
}

.form-field__select {
    &:not([multiple]) {
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 0;
        color: #999;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=) no-repeat right 50%;
        padding: .5em 1.5em .5em .5em;
    }
}

//== Required field
//
//## Mark fields as required
.app-field-required {
    position: relative;
}

.app-field-required .required-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -0.25em 0.25em 0em;
    background-color: #E8E8E8;
    border-color: #E8E8E8;
    padding: 0.5em 0.8em;
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0.325em;
    box-sizing: border-box;
    transition: background 0.1s linear;
    font-size: 75%;
}

.app-field-required .required-icon {
    background-color: transparent;
    position: absolute;
    top: 2.25em;
    right: 0em;
    z-index: 10;
    margin: 0em;
    width: 45px;
    height: 45px;
    padding: 0em;
    text-align: center;
    transition: color 0.2s ease;
}

.app-field-required .required-icon:after {
    position: absolute;
    content: "";
    right: 1px;
    top: 1px;
    z-index: -1;
    width: 0em;
    height: 0em;
    transition: border-color 0.2s ease;
}

.app-field-required .required-icon .text {
  margin: -3px 0 0 12px;
  color: $app-danger;
  font-size: 2.25rem;
  font-weight: 300;
}

//== Editable filed
//
//## Highlight inline editable fields
.app-field-editable {
    padding: .5rem;
    margin-bottom: 1rem;
    border: 1px solid $gray-300;
    &:hover {
        border-color: $color-sun-glow;
    }
}


//== Password toggle
//
//## Hide/show password in [type="password"] fields
::-ms-reveal,
::-ms-clear {
  display: none !important;
}

.hideShowPassword-toggle {
    width: 46px;
    height: 33px;
    min-height: 100%;
    overflow: hidden;
    text-indent: -999em;
  // background: transparent;
    background-color: transparent;
    background-image: url('../assets/img/icon-wink.png'); /* fallback */
    background-image: url('../assets/img/icon-wink.svg'), none;
    background-position: 0 center;
    background-repeat: no-repeat;
  border: 0;
  border-radius: 0.25em;
  color: #888;
  cursor: pointer;
  font-size: 0.75em;
  font-weight: bold;
  margin-right: 0.5em;
  padding: 0.5em;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.hideShowPassword-toggle-hide {
    background-position: -44px center;
}

.hideShowPassword-toggle:hover,
.hideShowPassword-toggle:focus {
  background-color: transparent;
  color: #555;
  outline: transparent;
}
