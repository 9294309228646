//
// Mixins
// ----------------------------------

//== Mixin providing a simple API for selector nesting
//
//## @param {String} $selector - Selector
@mixin when-inside($selector) {
    #{$selector} & {
        @content;
    }
}
