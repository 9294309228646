.app-fp-tile {
    flex: 1;
    display: flex;
    position: relative;
    // height: 100%;
}

// Event box added by javascript
.app-box-events {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex: 1;
}

.app-box-footer {
    @include media-breakpoint-up(sm) {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}

.app-panel-news-actions {
	text-align: right;
}
