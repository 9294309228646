//
// Cards displaying thumbnails and data collections
// ------------------------------------------------------


//== Card lists
//
//## Use a grid layout for card listings
.app-cards {
    @include make-row();
}

//** Base card styles
.app-card {
    display: block;
    width: 100%;
    padding: .5rem;
    background-color: #fff;
    @include when-inside('.app-cards') {
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }
        @include media-breakpoint-up(md) {
            @include make-col(3);
            &--filter {
                @include make-col(4);
            }
        }
    }
}

// Offset card to make it stand out
.app-card--offset {
    margin-top: 2rem;
}

//== Card layout
//
//## Reflow card contents based on viewport size
.app-card-header,
.app-card__header {
  padding: 0 .5rem .5rem;
  font-family: $headings-font-family;
    @include when-inside('.app-panel-news') {
        min-height: 3.75rem;
    }
}

//** Card content
.app-card-main,
.app-card__main {
  // border-top: 4px solid $app-secondary;
    &-boxed,
    &--bordered {
        border: 1px solid $gray-300;
        border-top: 4px solid $app-secondary;
    }
    &--bordered-top {
        border-top: 4px solid $app-secondary;
    }
}

//** Card footer
.app-card-footer,
.app-card__footer {
  padding: .5rem;
    @include when-inside('.app-box-events') {
        font-size: $font-size-sm;
    }
}

// Card content blocks
.app-card-block,
.app-card__block {
    padding: .5rem .25rem;
}

.app-card-item,
.app-card__item {
    font-size: $font-size-sm;
    @include media-breakpoint-up(xl) {
        font-size: 1rem;
    }
}

.app-card__date {
    font-family: $headings-font-family;
    font-weight: 500;
}

//== Gutter
//
//## Add spacing to cards
.app-card-gutter {
    margin: -1rem 0 0 -1rem;
    .app-card {
        padding: 1rem 0 0 1rem;
    }
}

//== Embedded card
//
//## Remove spacing for card inside card usecase
.app-subcard {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  border: 0;
}


//== Card title
//
//## Title is used inside the card-main container
.app-card-title {
  font-size: 1.25rem;
  font-weight: 500;
}

//** Set card content specific styles
.app-card-content {
  padding: .5rem;
  font-size: 1rem;
  address {
    margin-bottom: 0.75rem;
  }
  a {
    font-weight: 400;
    text-decoration: underline;
    color: #fff;
  }
}


//== Placeholder for missing card content
//
//## Provide call to actions and descriptions on empty cards
//## by providing highlighted card content
.app-card-placeholder {
  margin: 0.5rem auto;
  padding: 1rem;
  text-align: center;
  @include media-breakpoint-up(sm) {
    padding: 2rem 3rem;
    width: 66.66%;
  }
}

//** Round hellip sign
.app-card-placeholder-sign {
  @include size(4rem);
  margin: 0 auto;
  padding-top: 0.5rem;
  border-radius: 50%;
  border: 1px solid $gray-300;
  color: $gray-300;
  font-size: 2rem;
}
