.app-bulletin {
    padding: 1rem;
    margin-bottom: 2rem;
    border: 1px solid $gray-300;
    border-left-width: 5px;
}


.app-bulletin__abstract {
    font-weight: 700;
    @include when-inside('app-fonts-loaded') {
        font-weight: 600;
    }
}
