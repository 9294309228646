.app-ticker-bar {
    @include make-container();
    @include make-container-max-widths();
    background-color: #fff;
    margin-bottom: $spacer;
    border-left: 5px solid $gray-300;
}

.app-ticker {
    padding: 0;
}

.app-ticker-content {
 }

.app-ticker-title {
    color: $app-primary;
    font-weight: 400;
    @include media-breakpoint-up(md) {
        float:left;
        padding-top: .5rem;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        border: none;
    }
}

.app-ticker__headline {
    margin-top: .75rem;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.app-ticker__icon {
    float: right;
    @include media-breakpoint-up(md) {
        float: none;
    }
}

.app-ticker-main {
    margin-top: 1rem;
    margin-bottom: .5rem;
    padding: .5rem;
}

.app-ticker-actions {
    text-align: right;
    @include media-breakpoint-up(md) {
        @include make-col(2);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(1);
    }
}

.app-marquee {
    overflow: hidden;
}

/* ---- Single banner item ---- */
//
//## This part will be filled dynamically with
//## content and a lazyloaded background image
.app-marquee__item {
    position: relative;
    height: 8rem;
    min-height: 8rem;
    width: 100%;
    background-size: cover;
    background-position: top center;
    @include media-breakpoint-up(md) {
        height: 3rem;
        min-height: 3rem;
    }
}

.app-marquee__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #333;
    min-height: 8rem;
    @include media-breakpoint-up(md) {
        min-height: 3rem;
    }
}

.app-ticker__actions {
    padding-top: .5rem;
    padding-left: 1.25rem;
}

.app-marquee__bulletin {
    display: inline-block;
    position: relative;
    width: 100%;
}



/* ---- flickity overrides ---- */

.flickity-prev-next-button {
    background: transparent;
}

.flickity-prev-next-button .arrow {
    fill: #fff;
}

.flickity-prev-next-button:hover {
    background: none;
}

.flickity-page-dots {
    bottom: 0;
    @include media-breakpoint-up(sm) {
        text-align: right;
        // position: relative;
    }
}
