.app-lecture__detail {
    margin-bottom: 1.5rem;
}

.course-filter {
    margin-bottom: 1rem;
}

.course__modules {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.course__module {
    color: $text-muted;
}

.course-card {
    position: relative;
    &--muted {
        padding-top: 4rem;
    }
}

.course-card__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,.5);
}

.course-card__overlay-title {
    width: 120px;
    color: #000;
    font-size: 1rem;
    opacity: 1;
    background-color: transparent;
    > .app-callout {
        margin-top: 0;
    }
}

//== Course list filter bar
.filter__section {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.filter__block {
    flex: 1 0 0%;
    &--action {
        text-align: right;
        padding-right: 1rem;
    }
    &--container {
        border: 1px solid $gray-100;
    }
    &--hidden {
        display: none;
    }
}

.filter__items {
    width: 100%;
    display: flex;
    flex-direction: row;
    &--unit {
    }
}

.filter__item {
    @include make-col(3);
}

.form-field__select--hidden {
    opacity: 0;
    display: none;
}


.app-list {
    padding-left: 1rem;
    padding-right: 1rem;
}
// Result counter and clear filter actions
.app-list__result {
    display: flex;
    justify-content: space-between;
}
.result-count {
    font-weight: 300;
}

.app-button--filter-reset {
    margin-right: 1rem;
}
