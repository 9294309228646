// Site setup configlets - plone control panel
.configlets {
  list-style: none;
}

.inner-configlet {
	margin-bottom: .5rem;
  padding: .25rem;
  border: 1px solid #fff;
  text-align: center;
  font-size: .9rem;
  > a > span {
  	display: block;
  }
}