// Images and media support
//

//## Shim for exising classes
.app-img {
    @extend .img-fluid;
    margin-bottom: .5rem;
    line-height: 1;
    background-color: $gray-300;
    border-top: 4px solid $app-secondary;
    &-inverse {
        border-color: #000;
    }
    &-placeholder {
        width: 90px;
        height: 140px;
        text-align: center;
        padding-top: 1.5rem;
        color: $gray-300;
    }
    &-section {
        border: 1px solid $gray-300;
    }
}

.app-page-leadimage {
  margin-bottom: 1rem;
  img {
    @extend.img-fluid;
  }
}


.image-inline {
    @extend.img-fluid;
}

.image-left {
    @extend.img-fluid;
}

.embed-responsive {
  margin-bottom: 1rem;
}

.logo-vine-image {
  padding: 1rem;
}


// Prevent page reflow issues by utilizing the
// CSS instinct ration scaling technique
.ratio-container {
    position: relative;
}
.ratio-container:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
    padding-bottom: 56.25%;
    content: "";
}
.ratio-container > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* unknown ration variation */
.unknown-ratio-container > * {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

// Add min-height to lazyload elements
// to minimize content jumps
.lazyload,
.lazyloading {
    min-height: 200px;
}

// Add autosizes support
img[data-sizes="auto"] {
    display: block;
    width: 100%;
}
