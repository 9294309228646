//
// Footer
// -----------------------------------

.app-footer {
    padding-bottom: $spacer;
}

.app-footer-content {
  @extend .container;
  padding: 1rem;
  margin-top: .25rem;
  margin-bottom: 1rem;
}

.app-footer-actions {
	@extend .nav;
	// @extend .nav-inline;
	@include media-breakpoint-up(md) {

	}
}

.app-footer-action {
	@extend .nav-item;
}

.app-footer-link {
	@extend .nav-link;
	font-size: .85rem;
	text-transform: uppercase;
}
