// Course module editor styles
.app-module-editor {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.app-table-modules {
    @extend .table;
    @extend .table-striped;
    @extend .table-sm;
}

// Actions row
.app-table-actions {
    background-color: #fff;
    border-top: 2px solid #333;
    padding-top: 1rem;
}

.app-table-actions-cell {
    padding-top: 1rem !important;
    width: 20%;
}

.app-table__actions-cell--first {
    width: 40%;
}

//== Module selector
.module__select {

}

.module__select--hidden {
    display: none;
}

.module__select--active {
    display: block;
}


// TODO: refactor into standalone component
.app-inline-editable {
    position: relative;
    &::after {
        content: " ";
        z-index: 10;
        display: none;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        &:hover {
            display: block;
        }
    }
}

.app-inline-edit-bar {
    border-top: 1px solid #333;
}

.app-btn-inline-edit {
    float: right;
    margin-top: -1px;
}
