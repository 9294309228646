// Custom IGP section specific styles
//-----------------------------------------------

.subsection-institute-gesellschaftspolitik {
    .app-panel-main {
        border-top: 5px solid $app-color-igp;
    }
    .app-navitem-tab {
        background-color: $app-color-igp;
    }
    .app-img {
        border-top: 4px solid $app-color-igp;
    }
    .app-card-main {
        border-top: 4px solid $app-color-igp;
    }
}

.subsection-institute-globalefragen {
    .app-panel-main {
        border-top: 5px solid $app-color-igp;
    }
    .app-navitem-tab {
        background-color: $app-color-igp;
    }
    .app-img {
        border-top: 4px solid $app-color-igp;
    }
    .app-card-main {
        border-top: 4px solid $app-color-igp;
    }
}


