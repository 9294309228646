// Basic styles for Plone @@search view
// Note: these styles should probably be refactored or
// the template contents changes since these are mostly
// very ugly hacks

// Disable search form action menu
#searchform .actionMenu {
    display: none;
}

#searchResultsSort {
    float: right;
}
// Search result display
#search-results {
    padding-top: 1rem;
}
.searchResults > li {
    margin-bottom: 1rem;
}

.searchResults cite {
    display: block;
    font-size: .9rem;
}
