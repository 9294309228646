//
// Panelpage view styles
// ------------------------------------------------------


.app-panelpage {
    margin: $spacer 0;
    &.app-panelpage-editable {
        position: relative;
    }
}

// Mockup panel types

.panelpage-type {
    padding: $spacer;
    border: 1px solid $gray-600;
    background: #fff;
    .ppt {
        margin-bottom: floor($spacer / 4);
        &.ppt-title {
            width: 70%;
            height: ceil(($spacer * 0.75));
            margin-bottom: floor($spacer * 0.75);
            background: $gray-300;
        }
        &.ppt-lead {
            height: floor($spacer / 2);
            margin-bottom: floor($spacer * 0.5);
            background: $gray-100;
        }
        &.ppt-text {
            height: floor($spacer / 4);
            background: $gray-100;
        }
        &.ppt-media {
            height: 6rem;
            background: $gray-100;
            border: 1px solid $gray-300;
        }
    }
}


.app-panelpage-editbar {
    margin-bottom: ceil(($spacer * 1.5));
    color: $body-color;
    border-top: 1px solid $body-color;
}

.panelpage-label {
    font-weight: 400;
    &.level-1 {
        color: $gray-300;
    }
    &.level-2 {
        color: $gray-100;
    }
}

.panelpage-label + .panelpage-label {
    margin-left: ceil(($spacer * 2));
}

.ppe-toggle {
    color: #575757;
    display: table;
    &:before,
    &:after {
        background-image: linear-gradient(transparent 40%, #87a 40%, #87a 60%, transparent 60%);
        content: '';
        display: table-cell;
        width: 50%;
        background-clip: padding;
    }
    &:before {
        border-left: 1.5em solid transparent;
    }
    &:after {
        border-right: 1.5em solid transparent;
    }
}


.ppe-header {
    margin-bottom: 0;
    padding: $modal-header-padding;
    border-bottom: 1px solid rgba(252,252,252,0.1);
    min-height: ($modal-header-padding + $modal-title-line-height);
    background: $gray-100;
    color: $gray-600;
    .ppe-close {
        margin-right: $spacer;
        // font-size: 1.5rem;
        &:hover,
        &:focus {
            color: $close-color;
            text-decoration: none;
            cursor: pointer;
            opacity: .5;
        }
    }
    .ppe-header-title {
        font-size: 1.5rem;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: $spacer;
    }
}

.ppe-subtitle {
    padding: .5rem .5rem;
    background: #d9d9d9;
    text-transform: uppercase;
    font-size: $font-size-sm;
    color: #666;
    &.ppe-subtitle-default {
        margin-bottom: $spacer;
        background: $gray-100;
    }
}

.ppe-block {
    display: block;
    margin-bottom: $spacer;
    padding-left: .25rem;
    border-bottom: 1px solid $gray-100;
    border-right: ceil(($spacer / 3)) solid $gray-100;
    color: $body-color;
    &-grid {
        @include make-row();
    }
    .ppe-block-col-first {
        padding: ceil(($spacer / 2));
    }
    a {
        display: inline-block;
        color: $gray-300;
        &:hover {
            color: $gray-600;
        }
    }
    &.no-border {
        border: none;
    }
    &.ppe-block-hidden,
    &.ppe-block-private {
        border-left: ceil(($spacer / 3)) solid $red;
        .ppe-block-state {
            color: $red;
        }
    }
    &.ppe-block-visible,
    &.ppe-block-published {
        // border-right: ceil(($spacer / 3)) solid $alert-success-border;
        .ppe-block-state {
            color: $green;
        }
    }
    .ppe-block-title {
        position: absolute;
        top: 0;
        z-index: $zindex-popover;
        //border: 1px solid red;
        padding: 1rem;
        background: #fff;
        background: rgba(255, 255, 255, .5);
    }
}

.ppe-section {
    padding: $spacer 0;
    background-color: $modal-content-bg;
}

.ppe-action {
    display: block;
    margin-right: floor(($spacer / 2));
    line-height: ceil(($spacer * 2));
}

.ppe-sign {
    display: inline-block;
    @include size(4rem, 4rem);
    padding: ceil($spacer / 2);
    color: $gray-100;
    font-size: 2rem;
    text-align: center;
    line-height: 1;
}
.ppe-sign-ratio {
    color: $gray-600;
    border: 1px solid $gray-600;
    border-radius: 50%;
}
.ppe-sign-draggable {
    color: $gray-600;
    border: none;
}
.ppe-icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    border-color: $gray-800;
    background-color: $navbar-default-toggle-icon-bar-bg;
}
.ppe-icon-bar + .ppe-icon-bar {
    margin-top: 4px;
}


.ppe-block-placeholder {
    margin: $spacer 0;
    padding: ceil($spacer / 2);
    border: 1px solid $gray-100;
    color: $gray-300;
    text-align: center;
     .placeholder-sign {
        display: block;
        @include size(4rem);
        margin:0 auto;
        padding:ceil($spacer / 2);
        border:5px solid $gray-100;
        border-radius: 2rem;
        color:$gray-100;
        font-size:$spacer;
        text-align:center;
        line-height:1
    }
    .placeholder-title {
        padding-top: ceil($spacer * 0.75);
    }
    @include media-breakpoint-up(sm) {
        text-align: left;
        .placeholder-sign {
            margin:0 $spacer;
        }
    }
    &.placeholder-error {
        background: $red;
        border-color: $red;
        .placeholder-sign {
            color: $red;
        }
    }
    &.placeholder-get-started {
        text-align: center;
        padding: 4rem;
        color: $gray-600;
        .placeholder-sign {
            margin:$spacer auto 0;
        }
    }
}

.btn-ppe {
    @include button-variant($gray-100, $gray-600, $gray-800);
    border-radius: 0;
    &.btn-ppe-boxed {
        position:absolute;
        bottom: 0;
    }
}

.ppe-form {
    padding: $spacer 0;
}

.pp-icon {
    font-size: $font-size-base;
    &.pp-icon-md {
        font-size: ceil(($font-size-base * 2));
    }
    &.pp-icon-mlg {
        font-size: ceil(($font-size-base * 3));
    }
    &.pp-icon-lg {
        font-size: ceil(($font-size-base * 4));
    }
}
